<template>

<div class="players">

	<div v-for="player in players" :key="player.id" class="players-item">

		<div class="players-item-avatar" :style="{backgroundImage: 'url(data:image/jpeg;base64,' + player.avatar + ')'}">

			<app-icon icon="user" v-if="!player.avatar" />

		</div>

		<div class="players-item-info">

			<div class="players-item-info-name">{{ player.name }}</div>
			<div class="players-item-info-bgg" v-if="player.bgg">{{ player.bgg }}</div>

		</div>

		<app-icon icon="cancel" v-on:click.native="onCancelClick(player.id)" class="players-item-remove" v-if="!locked" />

	</div>

	<div v-if="!locked" class="players-scan" v-on:click="is.scanning = true">

		<app-icon icon="checkout.players" class="players-scan-icon" />
		Tap to scan other players

	</div>

	<com-scan :allowClose="true" :allowManual="true" v-on:scanned="onScanned" v-on:close="is.scanning = false" :checking="is.checking" v-if="is.scanning" />

</div>

</template>

<script>

export default {

	props: ['players', 'locked', 'ignoreLimit'],

	components: {
		'com-scan': () => import('../common/Scan')
	},

	data: function() {

		return {
			is: {
				scanning: false,
				checking: false
			},
			errors: {
				limit: 'Too many active loans',
				notfound: 'Cannot identify badge'
			}
		}

	},

	methods: {

		onCancelClick: function(value) {

			var players = this.$_.filter(this.$util.copy(this.players), function(player) {

				return player.id !== value

			})

			this.$emit('change', {
				value: this.$_.pluck(players, 'id'),
				player: false
			})

		},

		onScanned: function(value) {

			this.is.checking = true
			this.is.failed = false

			this.$api.get(['checkout/badge', value], {
				ignoreLimit: (this.ignoreLimit) ? 1 : 0
			}).then(function(json) {

				var players = this.$_.pluck(this.players, 'id')

				players.push(json.badge.id)

				this.$emit('change', {
					value: players,
					player: json.badge
				})

				this.is.checking = false
				
				this.is.scanning = false

			}.bind(this), function(json) {

				this.is.checking = false
				this.is.failed = json.reason

				this.$notify({
					message: this.errors[json.reason],
					type: 'error'
				})

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.players {
	flex-grow: 1;
	flex-basis: 0;
	overflow-y: auto;
}

.players-item {
	height: 64px;
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	background-color: #ccc;
	border-radius: 4px;
	align-items: center;
	margin: 0px 10px 10px 10px;
	color: #999;
}

.players-item-avatar {
	text-align: center;
	line-height: 64px;
	font-size: 32px;
	color: #999;
	width: 64px;
	border-radius: 4px 0px 0px 4px;
	margin-right: 10px;
	background-size: cover;
	background-position: 50% 50%;
	height: 64px;
	flex-shrink: 0;
	background-color: #ccc;
}

.players-item-info {
	flex-grow: 1;
}

.players-item-info-name {
	font-weight: 400;
	font-size: 16px;
	color: #666;
}

.players-item-info-bgg {
	font-weight: 300;
	font-size: 15px;
	color: #888;
	opacity:1;
}

.players-item-remove {
	color: #666;
	font-size: 24px;
	margin-right: 10px;
	cursor: pointer;
}

.players-scan {
	margin: 0px 10px;
	background-color: #fff;
	border: 1px dashed #ccc;
	flex-shrink: 0;
	height: 64px;
	align-items: center;
	text-align: center;
	font-size: 16px;
	cursor: pointer;
	font-weight: 500;
	display: flex;
	flex-direction: row;
	color: #999;
	margin-bottom: 10px;
	border-radius: 4px;
}

.players-scan-icon {
	font-size: 32px;
	width: 64px;
	margin-right: 10px;
}

</style>
